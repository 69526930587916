@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use 'variables' as var;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

@include spb.set-typography;


/* use this to grow */
.filler {
  display: flex;
  flex-grow: 1;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: #232323;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}



